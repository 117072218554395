import React from "react"
import logo from '../images/logo.svg';
import logoIcon from '../images/logo_icon.svg';
import logoInnerIcon from '../images/inner_logo.svg';
import { Row, Col, Container, ListGroup, Button } from "react-bootstrap"
import clsx from 'clsx';
import { FaCodepen } from 'react-icons/fa';
import { GoGlobe } from 'react-icons/go';
import { GiStack, GiCoffeeBeans } from 'react-icons/gi';

import { MdEmail } from 'react-icons/md';
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/index.scss";
import { Link } from "gatsby";

// TODO Add a banner about blog articles, open source and products once I have something to show
// TODO Add a section for products and blogs as well (navmenu)

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={['fullofcaffeine', 'api', 'react', 'fullstack', 'ruby', 'rails', 'rubyonrails', 'javascript', 'typescript', 'products', 'consulting', 'software', 'web development', 'mobile', 'remote']} />
    <Row className='dark-bg' style={{paddingBottom: '100px'}}>
      <Col xs={12}>
        <Row>
          <Col className='d-flex mb-5 justify-content-center'>
              <div className='d-none d-sm-block'>
                <img src={logo} className="header__logo" />
              </div>
              <div className='d-block d-sm-none'>
                <img src={logoIcon} className="header__logo_icon" />
              </div>
          </Col>
        </Row>
        <Row>
          <Col className='d-flex justify-content-center flex-column align-items-center'>
            <h1 className='text-center sub-slogan mb-5'>We drink coffee, code and launch great products.</h1>
            <Button href='mailto:hello@fullofcaffeine.co' className='d-flex align-items-center talk-btn mt-2' variant='primary'><MdEmail/>&nbsp;&nbsp;Let's talk!</Button>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className='banner light-bg'>
      <Col xs={12} className='d-flex flex-md-row align-items-center justify-content-center'>
        <div>
          <img src={logoInnerIcon} className='glyph' />
        </div>
        <div className='banner__text ml-4'>
          <h1>From Gist to Launch</h1>
          <p>
            We love to fiddle with new ideas and <strong>build stuff that adds value to people's lives</strong>. We're pragmatic and product-oriented. Getting from Gist to launching a product is our specialty.
        </p>
        </div>
      </Col>
    </Row>
    <Row className='banner dark-bg'>
      <Col xs={12} className='d-flex flex-md-row align-items-center justify-content-center'>
        <div className='banner__text'>
          <h1>Remote-first</h1>
        <p>
          <strong>fullOfCaffeine(Co.)</strong> is remote-first company. We strongly believe in remote work as it allows us to tap into talent that wouldn't be available locally otherwise. This provides flexibility for us and our clients, and keeps the morale high. We do, however, enjoy working on-site with our clients on a per need basis.
        </p>
        </div>
        <div>
          <GoGlobe className='glyph' />
        </div>
      </Col>
    </Row>
    <Row className='banner grey-bg'>
      <Col xs={12} className='d-flex flex-md-row align-items-center justify-content-center'>
        <div>
          <GiStack className='glyph'/>
        </div>
        <div className='banner__text ml-4'>
          <h1>Experience</h1>
          <p>
            We have a strong track record of over 13 years in the software industry. We specialize in Ruby and Javascript-based stacks, but we're fine using other languages and stacks if it makes sense to do so. We don't like needless complexity and strive to keep things as simple as we can. We love simplicity and think it's a worthwhile principle to follow.
        </p>
        </div>
     </Col>
    </Row>
    <Row className='banner light-bg'>
      <Col xs={12} className='d-flex flex-md-row align-items-center justify-content-center'>
        <div className='banner__text'>
          <h1>Yes, we ❤ coffee!</h1>
          <p>
            We're coffee lovers! The smell of and taste fresh coffee works as a catalyzer for us. Sometimes we drink tea, too. Whether or not you're also a coffee lover, say <a href='mailto: hello@fullofcaffeine.co'>hello!</a> We'd love to talk.
        </p>
        </div>
        <div>
          <GiCoffeeBeans className='glyph' />
        </div>
      </Col>
    </Row>
    <Row className='banner grey-bg'>
     <Col xs={12} className='d-flex justify-content-center flex-column align-items-center'>
        <h1>Need help?</h1>
        <p>
          Need to build a web or mobile product? Need help with an existing product? <strong>We can help</strong>.
        </p>
        <p>
        </p>
     </Col>
    </Row>
    <Row className='banner dark-bg'>
     <Col xs={12} className='d-flex justify-content-center flex-column align-items-center'>
        <p>
        <h3>Say hello. Let's have a [virtual] coffee together.</h3>
        </p>
        <p>
          <h1><a className='mailto' href='mailto:hello@fullofcaffeine.co'>hello@fullofcaffeine.co</a></h1>
        </p>
        <p>
        </p>
     </Col>
     <Col xs='5'>
     </Col>
    </Row>
  </Layout>
)

export default IndexPage
